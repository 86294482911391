define('busy-app/controllers/report/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),
    proFeature: Ember.inject.service('pro-feature'),
    interop: Ember.inject.service('interop'),

    allowPayrollReport: Ember.computed('features.payrollReport', function () {
      this.get('features.payrollReport');
      return this.get('proFeatureManager').allowProFeature('payroll-report');
    }),

    showPayrollProBadge: Ember.computed.not('allowPayrollReport'),

    allowBreakHoursReport: Ember.computed.alias('features.breakHoursReport'),

    proFeatureManager: null,

    init: function init() {
      this._super();

      this.set('proFeatureManager', this.get('proFeature').getManager());
    },
    requestPayrollReport: function requestPayrollReport(actionRequested) {
      this.get('proFeatureManager').requestAction('payroll-report', actionRequested);
    },


    signOffEnabled: Ember.computed('auth.organization.{safetySignature,timeAccuracy}', function () {
      if (this.get('auth.organization.safetySignature') || this.get('auth.organization.timeAccuracy') || this.get('auth.organization.breakPolicy')) {
        return true;
      }
      return false;
    }),

    actions: {
      employeePayroll: function employeePayroll() {
        var queryParams = {
          project: false,
          type: 'member'
        };

        this.transitionToRoute('report.payroll.index', { queryParams: queryParams });
      },
      employeeProjectPayroll: function employeeProjectPayroll() {
        var _this = this;

        var queryParams = {
          project: true,
          type: 'member-project'
        };

        this.requestPayrollReport(function () {
          return _this.transitionToRoute('report.payroll.index', { queryParams: queryParams });
        });
      },
      projectEmployeePayroll: function projectEmployeePayroll() {
        var _this2 = this;

        var queryParams = {
          project: true,
          type: 'project'
        };

        this.requestPayrollReport(function () {
          return _this2.transitionToRoute('report.payroll.index', { queryParams: queryParams });
        });
      },
      safetySignOff: function safetySignOff() {
        var _this3 = this;

        this.requestPayrollReport(function () {
          return _this3.transitionToRoute('report.safety-signature');
        });
      },
      equipmentByProject: function equipmentByProject() {
        var _this4 = this;

        this.get('proFeatureManager').requestAction('equipmentProjectReport', function () {
          return _this4.transitionToRoute('report.equipment-project');
        });
      },
      activityReport: function activityReport(type) {
        this.transitionToRoute('report.activity', { queryParams: { type: type } });
      },
      openReactApp: function openReactApp(path, customParameters, event) {
        var _this5 = this;

        this.requestPayrollReport(function () {
          return _this5.get('interop').openReactApp(path, customParameters, event);
        });
      },
      openReactAppFree: function openReactAppFree(path, customParameters, event) {
        this.get('interop').openReactApp(path, customParameters, event);
      },
      proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
        this.get('proFeatureManager').openModal();
      },
      proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
        this.get('proFeatureManager').closeModal();
      },
      proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
        this.get('proFeatureManager').onAllow();
      }
    }
  });
});